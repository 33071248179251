<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <btn-new to="/reports/new" />
          <v-btn
            color="info"
            class="ml-2"
            outlined
            @click="dialogFilters = true"
          >
            <v-icon
              v-text="'mdi-filter'"
              class="mr-2"
            />
            Filtros
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="reports"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50]
              }"
              @update:options="loadReports"
            >
              <template
                v-slot:[`item.date`]="{ item }"
              >
                {{ item.date | date }}
              </template>
              <template
                v-slot:[`item.totalHoursWorked`]="{ item }"
              >
                {{ `${item.totalHoursWorked.hours}:${item.totalHoursWorked.minutes}` }}
              </template>

              <template
                v-slot:[`item.printed`]="{ item }"
              >
                <v-switch
                  dense
                  :false-value="false"
                  :true-value="true"
                  :loading="loadingSetPrinted === item.id"
                  :input-value="item.printed"
                  @change="(e) => setPrinted(item, e)"
                ></v-switch>
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-menu
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      link
                      dense
                      @click="downloadPdf(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          Download PDF
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                         <v-progress-circular
                          v-if="item.id === downloadingPdfId"
                          indeterminate
                          :size="18"
                          :width="2"
                        ></v-progress-circular>
                        <v-icon 
                          v-else
                          v-text="'mdi-file-download'" 
                          small
                        />
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item
                      link
                      dense
                      :to="`/reports/${item.id}/edit`"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          Editar
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small>mdi-pencil</v-icon>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item
                      link
                      dense
                      @click="clickDeleteItem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          Deletar
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small>mdi-delete</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                
                  </v-list>
                </v-menu>

              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-delete
      :show="dialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
      @cancel="dialogDelete = false"
    />

    <dialog-filters
      v-model="dialogFilters"
      @filter="onFilter"
    />

  </div>
</template>

<script>
  import reportsApi from '@/api/reports'
  import _ from 'lodash'

  export default {

    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogFilters: () => import('./ReportsListFilters'),
    },

    data () {
      return {
        loadingDelete: false,
        loadingSetPrinted: null,
        dialogDelete: false,
        dialogFilters: false,
        deleteId: null,
        downloadingPdfId: null,
        loading: false,
        reportDetails: null,
        count: 0,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Data', value: 'date' },
          { text: 'Cliente', value: 'customerContact.customer.companyName' },
          { text: 'Criado por', value: 'user.name' },
          { text: 'Horas', value: 'totalHoursWorked' },
          { text: 'Impresso', value: 'printed' },
          { text: '', value: 'actions', align: 'right' },
        ],
        reports: [],
        pagination: {},
        filters: {},
      }
    },

    methods: {
      async loadReports () {
        try {
          this.loading = true

          const response = await reportsApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
            ..._.pickBy(this.filters)
          })

          this.reports = response.data.reports
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async downloadPdf (report) {
        try {
          this.downloadingPdfId = report.id
          const response = await reportsApi.pdf(report.id)
          const url = process.env.VUE_APP_API + 'downloads/reports/' + response.data.filename
          window.open(url, '_blank')
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.downloadingPdfId = null
        }
      },

      async setPrinted (report, printed) {
        try {
          this.loadingSetPrinted = report.id
          await reportsApi.setPrinted(report.id, { printed })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSetPrinted = null
        }
      },

      async clickDeleteItem (report) {
        this.deleteId = report.id
        this.dialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true
          await reportsApi.delete(this.deleteId)
          this.loadReports()
          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.dialogDelete = false
        }
      },

      onFilter (filters) {
        this.filters = filters
        this.loadReports()
      },

    },

  }
</script>
