var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('core-page-title'),_c('v-container',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('btn-new',{attrs:{"to":"/reports/new"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"info","outlined":""},on:{"click":function($event){_vm.dialogFilters = true}}},[_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s('mdi-filter')}}),_vm._v(" Filtros ")],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"1"}},[_c('v-data-table',{attrs:{"disable-filtering":"","disable-sort":"","server-items-length":_vm.count,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.reports,"options":_vm.pagination,"footer-props":{
              'items-per-page-options': [10, 20, 50]
            }},on:{"update:options":[function($event){_vm.pagination=$event},_vm.loadReports]},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.totalHoursWorked",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(((item.totalHoursWorked.hours) + ":" + (item.totalHoursWorked.minutes)))+" ")]}},{key:"item.printed",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"dense":"","false-value":false,"true-value":true,"loading":_vm.loadingSetPrinted === item.id,"input-value":item.printed},on:{"change":function (e) { return _vm.setPrinted(item, e); }}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Download PDF ")])],1),_c('v-list-item-action',[(item.id === _vm.downloadingPdfId)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":18,"width":2}}):_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-file-download')}})],1)],1),_c('v-list-item',{attrs:{"link":"","dense":"","to":("/reports/" + (item.id) + "/edit")}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Editar ")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.clickDeleteItem(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Deletar ")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)],1),_c('dialog-delete',{attrs:{"show":_vm.dialogDelete,"loading":_vm.loadingDelete},on:{"confirm":_vm.deleteItem,"cancel":function($event){_vm.dialogDelete = false}}}),_c('dialog-filters',{on:{"filter":_vm.onFilter},model:{value:(_vm.dialogFilters),callback:function ($$v) {_vm.dialogFilters=$$v},expression:"dialogFilters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }